import { omit } from 'lodash';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarTheme } from '../components/Sidebar/types';

type Value = string | number | object | unknown;
type GlobalContextType = {
  values: { [key: string]: Value };
  setValues: React.Dispatch<React.SetStateAction<{ [key: string]: Value }>>;
  theme: SidebarTheme;
  setTheme: React.Dispatch<React.SetStateAction<SidebarTheme>>;
  sidebarIsExpanded: boolean;
  setSidebarIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used with a GlobalContext');
  }

  return context;
};

export const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const [values, setValues] = useState<GlobalContextType['values']>({});
  const [theme, setTheme] = useState<SidebarTheme>('base');
  const [sidebarIsExpanded, setSidebarIsExpanded] = useState<boolean>(true);

  const location = useLocation();

  useEffect(() => {
    !location.pathname.includes('bconnect/extractor/interventions') &&
      setValues((filters) => omit(filters, ['interSearchForm']));
  }, [location]);

  const value = {
    values,
    setValues,
    theme,
    setTheme,
    sidebarIsExpanded,
    setSidebarIsExpanded
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
