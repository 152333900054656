import React, { useCallback, useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzTitle,
  ListPage,
  useNotif,
  BirdzNotif,
  useDialog,
  BirdzDialog
} from '@applications-terrains/birdz-react-library';
import {
  Alert,
  Button,
  Grid,
  LinearProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  IconButton,
  Skeleton
} from '@mui/material';
import { StyledTableBodyRow, StyledTableHeaderRow } from '../../styles/Table.styled';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Summary from './Summary';
import VisibilityIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { MonthlyStatement, MonthlyStatementCrInterventions } from './Types';
import { useGetMonths, useGetYears } from '../../hooks/datarefs';
import { removeDuplicates } from '../../utils';

type SubcontractorMonthlyStatementDetailsProps = {
  readOnly: boolean;
};

type SubcontractorMonthlyStatementDetailsParams = {
  month: string;
  year: string;
};

export default function SubcontractorMonthlyStatementDetails({
  readOnly
}: SubcontractorMonthlyStatementDetailsProps) {
  const { notif, notifOptions } = useNotif();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const navigate = useNavigate();

  const { id } = useParams<string>();
  const { month, year } = useParams<SubcontractorMonthlyStatementDetailsParams>();
  const { data: years } = useGetYears();
  const { data: months } = useGetMonths();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInterUpdating, setIsInterUpdating] = useState<boolean>(false);

  const endpoint = '/api/boe/monthly-statements/cr-intervention/';

  const [monthlyStatement, setMonthlyStatement] = useState<MonthlyStatement>({
    cr_interventions: []
  });

  const [checkedInter, setCheckedInter] = useState<MonthlyStatementCrInterventions[]>([]);
  const [addedInter, setAddedInter] = useState<MonthlyStatementCrInterventions[]>([]);

  const listFields = [
    {
      name: 'created_at',
      label: 'Début incident',
      transform: (value: any) => {
        return value ? moment.unix(value).format('DD/MM/YYYY') : null;
      }
    },
    {
      name: 'pdc_pdr',
      label: 'PCD/PDR'
    },
    {
      name: 'city',
      label: 'Commune'
    },
    {
      name: 'device_id',
      label: 'Adresse radio'
    },
    {
      name: 'end_intervention_date',
      label: 'Date fin intervention',
      transform: (value: any) => {
        return value ? moment(value).format('DD/MM/YYYY') : '-';
      }
    }
  ];
  const searchFields = [
    {
      name: 'created_at_after',
      label: 'Entre le',
      type: 'datepicker',
      className: 'col-sm-6'
    },
    {
      name: 'created_at_before',
      label: 'et le',
      type: 'datepicker',
      className: 'col-sm-6'
    },
    {
      name: 'technician',
      label: 'Technicien',
      options: {
        identifier: 'user',
        label: 'user__name',
        source: '/api/boe/subcontractors/technicians/',
        searchIsMulti: false
      },
      className: 'col-sm-6'
    }
  ];

  const deleteInter = (index: number) => {
    const interventions = [...addedInter];
    interventions.splice(index, 1);
    setAddedInter(interventions);
    saveStatement(interventions);
  };

  const fetchMonthlyStatement = useCallback(
    async (method = setLoading) => {
      method(true);
      await axios
        .get(`/api/boe/monthly-statements/monthly-statement/${id}/`)
        .then((response) => {
          setMonthlyStatement(response.data);
        })
        .catch(() => {
          method(false);
        })
        .finally(() => {
          method(false);
        });
    },
    [id]
  );

  useEffect(() => {
    if (id) {
      fetchMonthlyStatement();
    }
  }, [id]);

  useEffect(() => {
    if (monthlyStatement) {
      setAddedInter(monthlyStatement.cr_interventions);
    }
  }, [monthlyStatement]);

  useEffect(() => {
    const statementYear = years?.find((item) => {
      if (item.id.toString() === year) {
        return item;
      }
    });

    const statementMonth = months?.find((item) => {
      if (item.id.toString() === month) {
        return item;
      }
    });

    setMonthlyStatement({
      ...monthlyStatement,
      ...{ year__name: statementYear?.label, month__name: statementMonth?.label }
    });
  }, [year, month, years, months]);

  const addInter = () => {
    const newAddedInter = addedInter.concat(checkedInter);

    const interventions = removeDuplicates(newAddedInter);
    setAddedInter(interventions);
    setCheckedInter([]);
  };

  const addStatement = async () => {
    const interventionsIds = addedInter.map((inter) => {
      return inter.id;
    });

    const payload = {
      year: year,
      month: month,
      cr_interventions: interventionsIds
    };

    await axios.post('/api/boe/monthly-statements/monthly-statement/', payload).then(
      (response: any) => {
        const monthlyStatement = response.data;
        notif({
          content: 'Le relevé mensuel a été créé avec succès',
          type: 'success'
        });
        navigate(`/boe/monthly-statement/edit/${monthlyStatement?.id}`);
      },
      () => {
        notif({
          content: 'Une erreur est survenue lors de la création du relevé mensuel',
          type: 'error'
        });
      }
    );
  };

  const saveStatement = async (interventions?: MonthlyStatementCrInterventions[]) => {
    const payload: any = Object.assign({}, monthlyStatement);
    payload.cr_interventions = [];

    const inters = interventions || addedInter;
    inters.map((intervention) => {
      payload.cr_interventions.push(intervention?.id);
    });

    await axios.put(`/api/boe/monthly-statements/monthly-statement/${id}/`, payload).then(
      () => {
        notif({
          content: interventions
            ? 'La modification des interventions de ce relevé a bien été prise en compte'
            : 'Le relevé mensuel a été sauvegardé avec succès',
          type: 'success'
        });
        fetchMonthlyStatement(setIsInterUpdating);
      },
      () => {
        notif({
          content: 'Une erreur est survenue lors de le sauvegarde',
          type: 'error'
        });
      }
    );
  };

  const transmission = async () => {
    await axios
      .post(`/api/boe/monthly-statements/monthly-statement/${id}/transmit/`, {
        updated_at: monthlyStatement?.updated_at
      })
      .then(
        () => {
          notif({
            content: 'Le relevé mensuel a été transmis avec succès',
            type: 'success'
          });
          navigate(`/boe/monthly-statement/view/${id}`);
          navigate(0);
        },
        () => {
          notif({
            content: 'Une erreur est survenue lors de la transmission du relevé mensuel',
            type: 'error'
          });
        }
      );
  };

  return isLoading ? (
    <LinearProgress />
  ) : (
    <>
      <AppPaper>
        <Grid item xs={3} sx={{ mt: 2 }}>
          <BirdzTitle>Période</BirdzTitle>
          <Grid container spacing={2}>
            {monthlyStatement && (
              <>
                <Grid item xs={6}>
                  Année : <strong>{monthlyStatement.year__name}</strong>
                </Grid>
                <Grid item xs={6}>
                  Mois : <strong>{monthlyStatement.month__name}</strong>
                </Grid>
              </>
            )}
          </Grid>

          {!readOnly && <BirdzTitle>Interventions sur cette période</BirdzTitle>}

          {readOnly === false && (
            <>
              {!isInterUpdating ? (
                <ListPage
                  endpoint={`${endpoint}`}
                  searchFields={searchFields}
                  fields={listFields}
                  paginationOptions={{
                    pageSize: 10
                  }}
                  onSelectItems={(selectedInter: MonthlyStatementCrInterventions[]) => {
                    setCheckedInter(selectedInter);
                  }}
                  selectedItems={checkedInter}
                  hideItems={addedInter}
                  defaultOrder={['-updated_at']}
                />
              ) : (
                <Skeleton variant="rectangular" animation="pulse" height={800} />
              )}
            </>
          )}

          {!readOnly && (
            <Grid item xs={6} sx={{ mt: 1, textAlign: 'center' }}>
              <Button
                disabled={!checkedInter.length}
                variant="contained"
                onClick={() => addInter()}
              >
                Ajouter les interventions à la liste
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sx={{ mt: 3 }}>
            {addedInter?.length > 0 && (
              <>
                <BirdzTitle>Liste prévisionnelle de calcul du bon de commande</BirdzTitle>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                  <Table size="small">
                    <TableHead>
                      <StyledTableHeaderRow>
                        <TableCell align="center">Début incident</TableCell>
                        <TableCell align="center">PDC/PDR</TableCell>
                        <TableCell align="center">Commune</TableCell>
                        <TableCell align="center">Adresse radio</TableCell>
                        <TableCell align="center">Date fin d'intervention</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </StyledTableHeaderRow>
                    </TableHead>
                    <TableBody>
                      {addedInter.map((inter, index: number) => {
                        return (
                          <StyledTableBodyRow key={inter.id}>
                            <TableCell align="center">
                              {moment.unix(inter.created_at).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell align="center">{inter.pdc_pdr}</TableCell>
                            <TableCell align="center">{inter.city}</TableCell>
                            <TableCell align="center">{inter.device_id}</TableCell>
                            <TableCell align="center">
                              {inter.end_intervention_date
                                ? moment(inter.end_intervention_date).format('DD/MM/YYYY')
                                : null}
                            </TableCell>
                            <TableCell align="center">
                              <Link
                                to={`/boe/cr/interventions/details/${inter.id}`}
                                target="_blank"
                              >
                                <IconButton aria-label="Intervention détail">
                                  <VisibilityIcon id="visibilityIcon" />
                                </IconButton>
                              </Link>
                              {!readOnly && (
                                <IconButton
                                  onClick={() => {
                                    confirmDialog({
                                      title:
                                        "Supprimer l'intervention de la liste prévisionnelle du relevé mensuel",
                                      content:
                                        'Êtes-vous sûr de vouloir supprimer cette intervention de la liste prévisionnelle du relevé mensuel?',
                                      onValidate: () => {
                                        deleteInter(index);
                                        closeDialog();
                                      },
                                      onCancel: () => {
                                        closeDialog();
                                      }
                                    });
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              )}
                            </TableCell>
                          </StyledTableBodyRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Grid>

          <Grid item xs={6} sx={{ mt: 2 }}>
            <BirdzTitle>Résumé</BirdzTitle>
            {monthlyStatement && (
              <Summary
                fullSummary={false}
                monthlyStatement={monthlyStatement}
                interventions={addedInter.length}
              />
            )}
          </Grid>

          <Grid
            container
            item
            xs={12}
            sx={{ mt: 5, alignItems: 'center', justifyContent: 'center' }}
          >
            <Button
              variant="outlined"
              onClick={() => navigate('/boe/monthly-statement')}
              sx={{ mr: 2 }}
            >
              Retour à la liste
            </Button>

            {!id ? (
              <Button
                variant="contained"
                onClick={() => addStatement()}
                sx={{ mr: 2 }}
                disabled={addedInter.length === 0}
              >
                Ajouter
              </Button>
            ) : !monthlyStatement?.transmission_date ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => saveStatement()}
                  sx={{ mr: 2 }}
                  disabled={addedInter.length === 0}
                >
                  Enregistrer
                </Button>
                <Button variant="contained" onClick={() => transmission()}>
                  Transmettre
                </Button>
              </>
            ) : (
              <Alert severity="success" sx={{ height: '25px', alignItems: 'center' }}>
                Transmis le{' '}
                {moment(monthlyStatement?.transmission_date).format('DD/MM/YYYY à HH:mm')}{' '}
              </Alert>
            )}
          </Grid>
        </Grid>
        <BirdzDialog options={dialogOptions} />
        <BirdzNotif options={notifOptions} />
      </AppPaper>
    </>
  );
}
