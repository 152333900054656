import {
  Alert,
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  LinearProgress,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  AppPaper,
  BirdzDialog,
  BirdzNotif,
  BirdzTitle,
  useDialog,
  useNotif
} from '@applications-terrains/birdz-react-library';
import {
  AgentProps,
  BrandProps,
  CompanyProps,
  ContractProps,
  EventsProps,
  InterventionProps,
  ModuleFamiliesProps,
  RalProps,
  RawBrandProps,
  ReasonProps,
  SensorModelProps,
  SupportTypeProps
} from './types';
import axios from 'axios';
import InterventionAnomalies from './InterventionAnomalies';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import DeviceMap from '../../CR/Diagnostics/DeviceMap';
import { ModuleData } from '../../CR/Diagnostics/CR.types';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WarningIcon from '@mui/icons-material/Warning';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import { useGetBConnectInterventionsEvents } from '../../../hooks/datarefs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

type InterventionFormProps = {
  readOnly: boolean;
};

export default function InterventionForm({ readOnly }: InterventionFormProps) {
  const { id } = useParams();
  const { notif, notifOptions } = useNotif();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const goToInterventionsList = () => {
    navigate('/bconnect/extractor/interventions');
  };

  //handle events history
  const [expanded, setExpanded] = useState(false);
  const handleChange = () => {
    setExpanded(!expanded);
  };

  const { data: eventsList } = useGetBConnectInterventionsEvents();

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = (event: React.MouseEvent) => {
    event?.stopPropagation();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const saveImage = (downloadUrl: string, fileName: string): Promise<void> => {
    return new Promise<void>((resolve) => {
      const downloadImage = document.createElement('a');
      document.body.appendChild(downloadImage);
      downloadImage.setAttribute('download', fileName);
      downloadImage.href = downloadUrl;
      downloadImage.click();
      downloadImage.remove();
      resolve();
    });
  };

  const handleDownloadPhotos = async () => {
    for (let i = 0; i < currentPageData.length; i++) {
      const url = currentPageData[i];
      const fileName = `image_${i + 1}.jpg`;
      await saveImage(url, fileName);
      await delay(500);
    }
  };

  const [moduleData, setModuleData] = useState<ModuleData>();

  const [agents, setAgents] = useState<AgentProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>();
  const [contracts, setContracts] = useState<ContractProps[]>([]);
  const [moduleFamilies, setModuleFamilies] = useState<ModuleFamiliesProps[]>([]);

  const [intervention, setIntervention] = useState<InterventionProps | any>();

  const fetchAgents = async () => {
    const { data } = await axios.get(
      '/api/bconnect/webapp/customer/agents/?ordering=Id__Name&size=999999'
    );
    const results = data.results;
    const agents = results.map((agent: any) => {
      return { id: agent.Name, label: agent.Name };
    });
    setAgents(agents);
  };

  const fetchCompanies = async () => {
    const { data } = await axios.get('/api/boi/extractor/clients');
    const results = data.results;
    const companies = results.map((companie: any) => {
      return { id: companie.name, label: companie.name };
    });
    setCompanies(companies);
  };

  const fetchContracts = async () => {
    await axios.get('/api/boi/extractor/interventions/contracts/').then((response) => {
      const data = response.data;
      const contracts = data.map((contract: any) => {
        return { id: contract.contract_name, label: contract.contract_name };
      });
      setContracts(contracts);
    });
  };

  const fetchModuleFamilies = async () => {
    await axios.get('/api/boi/extractor/module-families/').then((response) => {
      const data = response.data;
      const moduleFamilies = data.map((family: any) => {
        return { id: family.Name, label: family.Name };
      });
      setModuleFamilies(moduleFamilies);
    });
  };

  const [reasons, setReasons] = useState<ReasonProps[]>([]);

  const fetchReasons = async () => {
    await axios.get('/api/bconnect/webapp/administrator/references/reason').then((response) => {
      const data = response.data;
      const reasons = data.map((reason: any) => {
        return { id: reason.Id, label: reason.DefaultLabel };
      });
      setReasons(reasons);
    });
  };

  const [supportType, setSupportType] = useState<SupportTypeProps[]>([]);
  const fetchSupportType = async () => {
    await axios.get('/api/bconnect/administrator/references/support-type').then((response) => {
      const data = response.data;
      data.sort((a: any, b: any) => a.Label.localeCompare(b.Label));
      const supportType = data.map((type: any) => {
        return { id: type.Id, label: type.Label };
      });
      setSupportType(supportType);
    });
  };

  const [ral, setRal] = useState<RalProps[]>([]);
  const fetchRal = async () => {
    await axios.get('/api/bconnect/administrator/references/RAL').then((response) => {
      const data = response.data;
      data.sort((a: any, b: any) => a.Label.localeCompare(b.Label));
      const ral = data.map((color: any) => {
        return { id: color.Id, label: color.Label };
      });
      setRal(ral);
    });
  };

  const [selectedEvent, setSelectedEvent] = useState<EventsProps>();
  const [isWaterMeter, setIsWaterMeter] = useState<boolean>();

  const fetchIntervention = async (version?: number, restored?: boolean) => {
    setLoading(true);
    const url = `/api/boi/extractor/interventions/${id}${version !== undefined ? `/v${version}` : ''}`;
    axios
      .get(url)
      .then(({ data }) => {
        setIsWaterMeter(data.module_family.toUpperCase() === 'WATERMETER');
        if (restored) {
          const sortedEvents = data.events.sort(
            (a: EventsProps, b: EventsProps) => b.created_at - a.created_at
          );
          setSelectedEvent(sortedEvents[0]);
        }
        setIntervention(data);
        if (eventTypeFilter) {
          handleFilterEventType({
            target: {
              value: eventTypeFilter
            }
          });
        } else {
          setEvents(data.events);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notif({
          type: 'error',
          content: `Erreur lors de la récupération de l'intervention : ${error.message}`
        });
      });
  };

  function removeKeys(obj: any, keysToRemove: any) {
    const { ...newObj } = obj;
    for (const key of keysToRemove) {
      delete newObj[key];
    }
    return newObj;
  }

  useEffect(() => {
    intervention && getSensorModels(intervention?.data['Marque compteur']);
    fetchBrands();
  }, [intervention]);

  const [brands, setBrands] = useState<BrandProps[]>([]); //brands formatted for select field
  const [rawDataOfBrands, setRawDataOfBrands] = useState<RawBrandProps[]>([]); //all brands
  const [sensorModels, setSensorModels] = useState<SensorModelProps[] | undefined>([]); //formatted array for select field with sensor brands or undefined if FamilyId 1

  const fetchBrands = async () => {
    if (isWaterMeter) {
      await axios
        .get(`/api/bconnect/administrator/references/fp2e-sensor-brands`)
        .then((response) => {
          const data = response.data;
          data.sort((a: any, b: any) => a.Label.localeCompare(b.Label));
          const brands = data.map((brand: any) => {
            return { id: brand.Id, label: brand.Label, code: brand.Code };
          });
          setBrands(brands);
        });
    } else {
      await axios.get(`/api/bconnect/administrator/references/sensor-brand`).then((response) => {
        const data = response.data;
        setRawDataOfBrands(data);
        data.sort((a: any, b: any) => a.Label.localeCompare(b.Label));
        const brands = data.map((brand: any) => {
          return { id: brand.Id, label: brand.Label };
        });
        setBrands(brands);
      });
    }
  };

  // to update the list of the available models for the sensor brand selected
  const getSensorModels = async (brandSelected: string) => {
    const data =
      rawDataOfBrands &&
      rawDataOfBrands.find((brand) => {
        if (brand.Label.toUpperCase() === brandSelected.toUpperCase()) {
          return brand;
        }
      });

    let models;
    if (data?.Models) {
      models = data.Models.map((model) => {
        return { id: model.Id, label: model.Label.toUpperCase() };
      });
    }
    setSensorModels(models);
  };

  const radioModes = [
    { id: 1, label: 'HR' },
    { id: 2, label: 'LoRaWAN' },
    { id: 3, label: 'SigFox' }
  ];

  const saveIntervention = (values: InterventionProps) => {
    //remove versions, anomalies and events in payload
    const newValues = removeKeys(values, ['anomalies', 'events', 'versions']);
    const payload: InterventionProps = Object.assign({}, newValues);
    axios
      .put(`/api/boi/extractor/interventions/${id}/`, payload)
      .then(() => {
        notif({
          type: 'success',
          content:
            "Intervention enregistrée avec succès, redirection sur la page de consultation de l'intervention en cours"
        });
        setTimeout(() => {
          navigate(`/bconnect/extractor/interventions/view/${id}`);
          fetchIntervention(undefined, true);
        }, 1500);
      })
      .catch((error) => {
        notif({
          type: 'error',
          content: `Erreur : Intervention non enregistrée. ${error.message}`
        });
      });
  };

  const handleRefresh = (version?: number) => {
    fetchIntervention(version);
  };

  useEffect(() => {
    fetchIntervention();
    fetchAgents();
    fetchCompanies();
    fetchContracts();
    fetchModuleFamilies();
    fetchReasons();
    fetchSupportType();
    fetchRal();
    fetchBrands();
  }, [id]);

  //   load coords gps from intervention object to pass them to Leaflet component
  useEffect(() => {
    if (intervention && intervention.latitude && intervention.longitude) {
      setModuleData({
        module_address: '',
        contract: '',
        city: '',
        latitude: Number(intervention?.latitude),
        longitude: Number(intervention?.longitude),
        device_type: '',
        average_exclusive_module: '',
        contract_label: '',
        contract_exp_date: '',
        pdc_id: '',
        phone: ''
      });
    }
  }, [intervention]);

  const displayDataTextField = (
    fieldName: string,
    fieldLabel: string,
    options: {
      type?: string;
      endAdornment?: JSX.Element;
      read_only?: boolean;
    } = {}
  ) => {
    const isTextarea = options?.type === 'textarea';

    return (
      <TextField
        label={fieldLabel}
        multiline={isTextarea}
        rows={4}
        InputProps={{
          endAdornment: options?.endAdornment,
          readOnly: options?.read_only
        }}
        type={options.type ?? 'text'}
        value={intervention && intervention.data && intervention.data[fieldName]}
        onChange={(event) => {
          if (intervention && intervention.data) {
            setIntervention({
              ...intervention,
              data: {
                ...intervention.data,
                [fieldName]: event.target.value
              }
            });
          }
        }}
        variant={isTextarea ? 'outlined' : 'standard'}
        sx={{
          m: 1,
          minWidth: 300
        }}
        disabled={readOnly}
      />
    );
  };

  const displayDataSelectField = (
    fieldName: string,
    fieldLabel: string,
    fieldValues: Partial<{ id: number; label: string; code?: string }>[],
    options: {
      read_only?: boolean;
      id?: boolean;
      caseSensitive?: boolean;
    } = {}
  ) => {
    return (
      <TextField
        label={fieldLabel}
        select
        InputProps={{
          readOnly: options?.read_only
        }}
        value={(intervention && intervention.data && intervention.data[fieldName]) || ''}
        onChange={(event) => {
          if (intervention && intervention.data) {
            setIntervention({
              ...intervention,
              data: {
                ...intervention.data,
                [fieldName]: event.target.value
              }
            });

            if (fieldName === 'Marque compteur') {
              getSensorModels(event.target.value);
            }
          }
        }}
        sx={{
          m: 1,
          minWidth: 300
        }}
        variant="standard"
        disabled={readOnly}
      >
        <MenuItem value={''}>{''}</MenuItem>
        {fieldValues &&
          fieldValues.map((fieldValue) =>
            options.id ? (
              <MenuItem key={fieldValue.id} value={fieldValue.id}>
                {fieldValue.label}
              </MenuItem>
            ) : options.caseSensitive ? (
              <MenuItem
                key={fieldValue.id}
                value={fieldValue.label && fieldValue.label.toUpperCase()}
              >
                {fieldValue.label && fieldValue.label.toUpperCase()}{' '}
                {fieldValue.code ? '(' + fieldValue.code + ')' : null}
              </MenuItem>
            ) : (
              <MenuItem key={fieldValue.id} value={fieldValue.label}>
                {fieldValue.label}
              </MenuItem>
            )
          )}
      </TextField>
    );
  };

  const displayDateTimePicker = (
    fieldName: string,
    fieldLabel: string,
    options: {
      isTimestamp?: boolean;
      read_only?: boolean;
      inData?: boolean;
    } = {}
  ) => {
    let date: any = null;
    const fieldValue = options.inData ? intervention?.data?.[fieldName] : intervention?.[fieldName];

    if (options.isTimestamp && typeof fieldValue === 'number') {
      date = moment.unix(fieldValue);
    }

    const value = date || fieldValue;

    return (
      <DateTimePicker
        label={fieldLabel}
        readOnly={readOnly}
        value={value ? new Date(value) : null}
        onChange={(event) => {
          if (event) {
            const isoString = event.toISOString();
            setIntervention((prev: InterventionProps) => ({
              ...prev,
              ...(options.inData
                ? { data: { ...prev.data, [fieldName]: isoString } }
                : { [fieldName]: isoString })
            }));
          }
        }}
        slots={{ textField: (params) => <TextField {...params} /> }}
        disabled={options.read_only ?? readOnly}
      />
    );
  };

  const displayTextField = (
    fieldName: string,
    fieldLabel: string,
    options: {
      type?: string;
      isDate?: boolean;
      isTimestamp?: boolean;
      endAdornment?: JSX.Element;
      read_only?: boolean;
    } = {}
  ) => {
    const isTextarea = options?.type === 'textarea';

    return (
      <TextField
        label={fieldLabel}
        multiline={isTextarea}
        rows={4}
        InputProps={{
          endAdornment: options?.endAdornment,
          readOnly: options?.read_only
        }}
        type={options.type ?? 'text'}
        value={
          intervention &&
          (intervention[fieldName] === null || typeof intervention[fieldName] === 'undefined'
            ? ''
            : options?.isDate || options?.type === 'date'
              ? moment(intervention[fieldName]).format('DD/MM/YYYY HH:mm')
              : options?.isTimestamp
                ? moment.unix(intervention[fieldName]).format('DD/MM/YYYY HH:mm')
                : intervention[fieldName])
        }
        onChange={(event) => {
          intervention &&
            setIntervention({
              ...intervention,
              [fieldName]: event.target.value
            });
        }}
        variant={isTextarea ? 'outlined' : 'standard'}
        sx={{
          m: 1,
          minWidth: 300
        }}
        disabled={readOnly}
      />
    );
  };

  const displaySelectField = (
    fieldName: string,
    fieldLabel: string,
    fieldValues: Partial<{ id: number | string; label: string }>[],
    options: {
      read_only?: boolean;
    } = {}
  ) => {
    return (
      <TextField
        label={fieldLabel}
        select
        InputProps={{
          readOnly: options?.read_only
        }}
        value={
          intervention &&
          (intervention[fieldName] === null || typeof intervention[fieldName] === 'undefined'
            ? ''
            : intervention[fieldName])
        }
        onChange={(event) => {
          intervention &&
            setIntervention({
              ...intervention,
              [fieldName]: event.target.value
            });
        }}
        sx={{
          m: 1,
          minWidth: 300
        }}
        variant="standard"
        disabled={readOnly}
      >
        <MenuItem value={''} />
        {fieldValues.map((fieldValue) => (
          <MenuItem key={fieldValue.id} value={fieldValue.id}>
            {fieldValue.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const displayDataCheckBox = (
    fieldName: string,
    fieldLabel: string,
    options: {
      disabled?: boolean;
    } = {}
  ) => {
    const checked = !!intervention?.data[fieldName];

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(event) => {
                if (intervention && intervention.data) {
                  setIntervention({
                    ...intervention,
                    data: {
                      ...intervention.data,
                      [fieldName]: event.target.checked
                    }
                  });
                }
              }}
              color="primary"
              disabled={options?.disabled}
            />
          }
          label={fieldLabel}
        />
      </div>
    );
  };

  const translate = (value: string) => {
    switch (value) {
      case 'EligibilityAudit':
        return 'Audit';
      case 'AttributeInstall':
        return "Installation d'un attribut";
      case 'AttributeMaintenance':
        return "Maintenance d'un attribut";
      case 'MeterInstall':
        return "Installation d'un compteur";
      case 'MeterMaintenance':
        return "Maintenance d'un compteur";
      case 'DeviceInstall':
        return "Installation d'un module";
      case 'DeviceDump':
        return "Dépose d'un module";
      case 'DeviceReinstall':
        return "Repose d'un module";
      case 'DeviceMaintenance':
        return "Maintenance d'un module";
      case 'RepeaterMaintenance':
        return "Maintenance d'un répéteur / Bridge";
      case 'VisualIndex':
        return 'Relève visuelle';
      case 'RadioIndex':
        return 'Radio-relevé';
      case 'DeviceUpdate':
        return 'MAJ des informations du module';
      case 'RepeaterUpdate':
        return 'MAJ des informations du répéteur';
      case 'MeterMaintenanceWithCs':
        return "Maintenance d'un compteur avec enquête branchement";
      case 'DeviceMaintenanceWithCs':
        return "Maintenance d'un module avec enquête branchement";
      case 'MeterInstallWithCs':
        return "Installation d'un compteur avec enquête branchement";
      case 'DeviceInstallWithCs':
        return "Installation d'un module avec enquête branchement";
      case 'ConnectionSurveyWithCs':
        return 'Enquête branchement';
      case 'DeviceInstallLight':
        return "Installation d'un module - Light";
      case 'DeviceMaintenanceLight':
        return "Maintenance d'un module - Light";
      case 'RadioIndexRead':
        return 'Radio-relevé unitaire';
      default:
        return value;
    }
  };

  const [mostRecentEvent, setMostRecentEvent] = useState<EventsProps>();

  const [showExportPopup, setShowExportPopup] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);

  const handleRestorePopup = (event: React.MouseEvent) => {
    event?.stopPropagation();
    setShowExportPopup(!showExportPopup);
  };

  const handleCancelPopup = (event: React.MouseEvent) => {
    event?.stopPropagation();
    setShowCancelPopup(!showCancelPopup);
  };

  const handleGridClick = async (item: EventsProps) => {
    setSelectedEvent(item);
    handleRefresh(item.intervention_version);
  };

  const restoreInterventionAtEvent = async (event: React.MouseEvent, eventId: number) => {
    event?.stopPropagation();

    await axios
      .post(`/api/boi/extractor/events/${eventId}/restore/`)
      .then(() => {
        notif({
          type: 'success',
          content: 'Intervention restaurée avec succès'
        });
        setTimeout(() => {
          fetchIntervention(undefined, true);
        }, 1500);
      })
      .catch((error) => {
        notif({
          type: 'error',
          content: `Erreur : Intervention non restaurée. ${error.message}`
        });
      });

    setShowExportPopup(!showExportPopup);
  };

  const displayEvent = (events: EventsProps[]) => {
    //sort events in desc order
    const sortedEvents = events.sort((a, b) => {
      return b.created_at - a.created_at;
    });

    //save the most recent id (to not display restore button)
    sortedEvents && setMostRecentEvent(sortedEvents[0]);
    if (!selectedEvent) {
      setSelectedEvent(sortedEvents[0]);
    }

    return (
      <Grid
        container
        spacing={2}
        sx={{
          ml: 0,
          p: 2,
          overflow: 'auto',
          height: 'calc(100vh - 22rem)'
        }}
      >
        {sortedEvents &&
          sortedEvents.map((item) => {
            return (
              <Grid
                container
                spacing={2}
                sx={{
                  border: 1,
                  borderColor: 'white',
                  borderRadius: 4,
                  backgroundColor: item.id === selectedEvent?.id ? 'gray' : 'lightgray',
                  mt: 2,
                  p: 1
                }}
                key={item.id}
                onClick={() => handleGridClick(item)}
              >
                <Grid item xs={6}>
                  <strong>Le {moment.unix(item.created_at).format('DD/MM/YYYY à HH:mm')}</strong>
                </Grid>

                {selectedEvent?.id === item.id &&
                (events.length === 1 || item.id !== mostRecentEvent?.id) ? (
                  <Grid item xs={6} sx={{ height: '50px' }}>
                    <Box sx={{ justifyContent: 'flex-end' }}>
                      <Button variant="contained" onClick={(event) => handleRestorePopup(event)}>
                        Restaurer
                      </Button>

                      <Dialog
                        open={showExportPopup}
                        onClose={handleRestorePopup}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">{'Confirmation'}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Etes-vous sûr de vouloir restaurer les données de l'événement{' '}
                            {item.event} datant du{' '}
                            {moment.unix(item.created_at).format('DD/MM/YYYY')} ?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button autoFocus onClick={handleRestorePopup} color="primary">
                            Annuler
                          </Button>
                          <Button
                            autoFocus
                            color="primary"
                            onClick={(event) => restoreInterventionAtEvent(event, item.id)}
                          >
                            Restaurer
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={6} sx={{ height: '50px' }}></Grid>
                )}

                <Grid item xs={12}>
                  {item.event}
                </Grid>
                <Grid item xs={12}>
                  Par : {item.created_by_name ? item.created_by_name : '...'}
                </Grid>

                <Grid container alignItems="center" spacing={2} sx={{ mt: 1 }}>
                  {item.intervention_status && (
                    <>
                      <Grid item xs={1}>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          sx={{
                            backgroundColor: item.id === selectedEvent?.id ? 'lightgray' : 'gray',
                            color: item.id === selectedEvent?.id ? 'black' : 'white',
                            borderRadius: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 'auto'
                          }}
                        >
                          <div style={{ fontSize: '12px' }}>
                            {item.intervention_status === 'Done'
                              ? 'Terminée'
                              : item.intervention_status}
                          </div>
                        </Box>
                      </Grid>
                    </>
                  )}
                  {item.anomaly_status && (
                    <>
                      <Grid item xs={1}>
                        <WarningIcon />
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          sx={{
                            backgroundColor: item.id === selectedEvent?.id ? 'lightgray' : 'gray',
                            color: item.id === selectedEvent?.id ? 'black' : 'white',
                            borderRadius: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <div style={{ fontSize: '12px' }}>
                            {item.anomaly_status === 'IN_ANOMALY'
                              ? 'En anomalie'
                              : item.anomaly_status === 'WITHOUT_ANOMALY'
                                ? 'Sans anomalie'
                                : item.anomaly_status === 'CORRECTED'
                                  ? 'Corrigée'
                                  : null}
                          </div>
                        </Box>
                      </Grid>
                    </>
                  )}
                  {item.validation_status && (
                    <>
                      <Grid item xs={1}>
                        <CheckCircleIcon />
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          sx={{
                            backgroundColor: item.id === selectedEvent?.id ? 'lightgray' : 'gray',
                            color: item.id === selectedEvent?.id ? 'black' : 'white',
                            borderRadius: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <div style={{ fontSize: '12px' }}>
                            {item.validation_status === 'TO_VALIDATE'
                              ? 'A valider'
                              : item.validation_status === 'VALIDATED'
                                ? 'Validée'
                                : item.validation_status}
                          </div>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            );
          })}

        {sortedEvents.length === 0 && (
          <>
            <Grid container spacing={2} key="no-events">
              <Box sx={{ m: 'auto', mt: 3, width: '100%' }}>
                <Alert severity="info">Aucun événement</Alert>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const exportPdf = async () => {
    return;
  };

  const cancelIntervention = async (event: React.MouseEvent) => {
    event.stopPropagation();
    await axios
      .post(`/api/boi/extractor/interventions/${id}/cancel/`)
      .then(() => {
        notif({
          type: 'success',
          content: 'Intervention annulée avec succès'
        });
        setTimeout(() => {
          handleRefresh();
        }, 1500);
      })
      .catch((error) => {
        notif({
          type: 'error',
          content: `Erreur : Intervention non annulée. ${error.message}`
        });
      });

    setShowCancelPopup(!showCancelPopup);
  };

  const validate = async () => {
    await axios
      .post(`/api/boi/extractor/interventions/${id}/validate/`)
      .then(() => {
        notif({
          type: 'success',
          content: 'Intervention validée avec succès'
        });
        setTimeout(() => {
          handleRefresh();
        }, 1500);
      })
      .catch((error) => {
        notif({
          type: 'error',
          content: `Erreur : Intervention non validée. ${error.message}`
        });
      });
  };

  const [eventTypeFilter, setEventTypeFilter] = useState<string>('');
  const [events, setEvents] = useState<EventsProps[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    if (events.length === 1) {
      // fetchIntervention(events[0].intervention_version); //commenter cette ligne permet d'afficher importé dans b-tech
      setSelectedEvent(events[0]);
    }
  }, [events]);

  useEffect(() => {
    if (intervention) {
      !eventTypeFilter && setEventTypeFilter('Tous les événements');
      // events.length === 0 && setEvents(intervention.events); //cette ligne empêche le bon affichage en cas d'absence d'evenement
      const urlString = intervention.data['Lien photo'];
      const imageUrls = urlString?.split(' | ');
      setImageUrls(imageUrls);
    }
  }, [intervention, events, eventTypeFilter]);

  const handleFilterEventType = (event: any) => {
    setEventTypeFilter(event.target.value);

    if (event.target.value === 'Tous les événements') {
      intervention && setEvents(intervention.events);
    } else {
      const eventsFiltered = intervention?.events.filter((item: EventsProps) => {
        if (item.event.includes(event.target.value)) {
          return item;
        }
      });

      if (eventsFiltered) {
        setEvents(eventsFiltered);
      }
    }
  };

  const [modalMedia, setModalMedia] = useState<string | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  const currentPageData = imageUrls?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  const handleModalMediaChange = (index: number) => {
    const globalIndex = (currentPage - 1) * itemsPerPage + index;
    setCurrentModalIndex(globalIndex);
    setModalMedia(imageUrls[globalIndex]);
  };

  const handlePrevImage = (event: React.MouseEvent) => {
    event.stopPropagation();
    const newIndex = (currentModalIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentModalIndex(newIndex);
    setModalMedia(imageUrls[newIndex]);
  };

  const handleNextImage = (event: React.MouseEvent) => {
    event.stopPropagation();
    const newIndex = (currentModalIndex + 1) % imageUrls.length;
    setCurrentModalIndex(newIndex);
    setModalMedia(imageUrls[newIndex]);
  };

  return (
    <>
      {
        <Formik
          initialValues={intervention ? intervention : {}}
          enableReinitialize={true}
          onSubmit={(values: InterventionProps) => {
            saveIntervention(values);
          }}
          // validationSchema={Yup.object({
        >
          {({ handleSubmit, validateForm }) => {
            return isLoading ? (
              <LinearProgress />
            ) : (
              <Form>
                <Grid container justifyContent="flex-end" sx={{ m: 1, pb: 1, zIndex: 1 }}>
                  {readOnly && (
                    <>
                      <Button type="button" variant="outlined" onClick={() => exportPdf()} disabled>
                        Export PDF
                      </Button>

                      <Button
                        type="button"
                        variant="outlined"
                        sx={{ ml: 1 }}
                        onClick={() => goToInterventionsList()}
                      >
                        Retour
                      </Button>

                      {!intervention?.exportable ? (
                        <>
                          <Tooltip title="Cette intervention est déjà annulée">
                            <span>
                              <Button
                                type="button"
                                variant="outlined"
                                sx={{ ml: 1 }}
                                disabled={!intervention?.exportable}
                              >
                                Annuler l'intervention
                              </Button>
                            </span>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Button
                            type="button"
                            variant="outlined"
                            sx={{ ml: 1 }}
                            onClick={(event) => handleCancelPopup(event)}
                            disabled={!intervention?.exportable}
                          >
                            Annuler l'intervention
                          </Button>

                          <Dialog
                            open={showCancelPopup}
                            onClose={handleCancelPopup}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{'Confirmation'}</DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Etes-vous sûr de vouloir annuler l'intervention ? Une intervention
                                annulée ne pourra pas être exportée.
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button autoFocus onClick={handleRestorePopup} color="primary">
                                Non
                              </Button>
                              <Button
                                autoFocus
                                color="primary"
                                onClick={(event) => cancelIntervention(event)}
                              >
                                Oui
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </>
                      )}

                      <Tooltip title="Uniquement pour les interventions au statut 'Terminée', et qui sont rattachées à un client">
                        <span>
                          <Button
                            variant="contained"
                            sx={{ ml: 1 }}
                            component={Link}
                            to={`/bconnect/extractor/interventions/edit/${id}`}
                            disabled={
                              intervention?.bconnect_intervention_status !== 'Done' ||
                              !intervention.client
                            }
                          >
                            Modifier
                          </Button>
                        </span>
                      </Tooltip>

                      <Button
                        variant="contained"
                        type="button"
                        sx={{ ml: 1, mr: 1 }}
                        onClick={() => validate()}
                        disabled={
                          intervention?.bconnect_intervention_status !== 'Done' ||
                          intervention?.anomaly_status === 'IN_ANOMALY' ||
                          intervention.validation_status !== 'TO_VALIDATE' ||
                          intervention?.validation_date
                        }
                      >
                        Valider
                      </Button>
                    </>
                  )}
                  {!readOnly && (
                    <>
                      <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mr: 1 }}>
                        Retour
                      </Button>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() => {
                          confirmDialog({
                            content: 'Souhaitez-vous annuler vos saisies?',
                            onValidate: () => {
                              handleRefresh();
                              closeDialog();
                            },
                            onCancel: () => {
                              closeDialog();
                            },
                            okButtonLabel: 'Oui',
                            cancelButtonLabel: 'Non'
                          });
                        }}
                      >
                        Annuler
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ ml: 1, mr: 1 }}
                        onClick={() => {
                          validateForm().then((errors: any) => {
                            const errorsList: string[] = Object.keys(errors);
                            if (errorsList.length) {
                              notif({
                                type: 'error',
                                content: (
                                  <div>
                                    <>
                                      {
                                        'Veuillez corriger les erreurs suivantes sur le formulaire :'
                                      }
                                    </>
                                    <ul>
                                      {errorsList.map((error) => (
                                        <li key={error}>{error}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )
                              });
                            } else handleSubmit();
                          });
                        }}
                      >
                        Enregistrer
                      </Button>
                    </>
                  )}
                </Grid>

                {intervention && (
                  <Grid container sx={{ height: 'calc(100vh - 20vh)', overflow: 'auto' }}>
                    <Grid item xs={readOnly && expanded ? 9 : readOnly && !expanded ? 11.5 : 12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <BirdzTitle>
                            ANOMALIES{' '}
                            <Tooltip title="Anomalies">
                              <Badge
                                badgeContent={
                                  intervention.anomalies.length
                                    ? intervention.anomalies.length
                                    : '0'
                                }
                                color={intervention.anomalies.length > 0 ? 'error' : 'primary'}
                              >
                                <ReportProblemIcon color="action" />
                              </Badge>
                            </Tooltip>
                          </BirdzTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                          <InterventionAnomalies
                            anomalies={intervention.anomalies}
                            readOnly={readOnly}
                            onRefresh={handleRefresh}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    {readOnly && (
                      <Grid
                        item
                        xs={expanded ? 3 : 0.5}
                        sx={{
                          px: 1,
                          position: 'absolute',
                          right: 0,
                          overflow: 'hidden',
                          width: '100%'
                        }}
                      >
                        <Accordion expanded={expanded} onChange={handleChange}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="events-content"
                            id="events"
                          >
                            <Grid container justifyContent="center">
                              <Box>
                                <Tooltip title="Historique des événements">
                                  <Badge badgeContent={intervention.events.length} color="primary">
                                    <ManageHistoryIcon color="action" />
                                  </Badge>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{ display: 'flex' }}>
                              <FormControl variant="standard" fullWidth>
                                <Select value={eventTypeFilter} onChange={handleFilterEventType}>
                                  <MenuItem value={'Tous les événements'}>
                                    Tous les événements
                                  </MenuItem>
                                  {eventsList &&
                                    eventsList.map((event: any) => {
                                      return (
                                        <MenuItem key={event.value} value={event.value}>
                                          {event.label}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Box>

                            {events.length !== 0 && (
                              <Box sx={{ mt: 2 }}>
                                <strong>
                                  {events.length} résultat{events.length > 1 && 's'}
                                </strong>
                              </Box>
                            )}

                            {displayEvent(events)}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      xs={readOnly && expanded ? 9 : readOnly && !expanded ? 11.5 : 12}
                    >
                      <Grid item xs={6} sx={{ mt: 2 }}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <BirdzTitle style={{ display: 'flex', alignItems: 'center' }}>
                              <Box>{intervention.reference}</Box>
                              {intervention.anomaly_status === 'IN_ANOMALY' && (
                                <Box sx={{ mt: 1, ml: 1 }}>
                                  <ReportProblemIcon style={{ color: '#e60000' }} />
                                </Box>
                              )}
                              {intervention.validation_status === 'VALIDATED' && (
                                <Box sx={{ mt: 1, ml: 1 }}>
                                  <CheckCircleIcon style={{ color: 'green' }} />
                                </Box>
                              )}
                            </BirdzTitle>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={2}>
                              <TextField
                                label="Statut"
                                type={'text'}
                                value={
                                  intervention.bconnect_intervention_status === 'Done'
                                    ? 'Terminée'
                                    : intervention.bconnect_intervention_status
                                }
                                variant={'standard'}
                                sx={{
                                  m: 1,
                                  minWidth: 300,
                                  width: 'auto'
                                }}
                                disabled={true}
                              />
                              <TextField
                                label="Type d'intervention"
                                type={'text'}
                                value={translate(intervention.intervention_type)}
                                variant={'standard'}
                                sx={{
                                  m: 1,
                                  minWidth: 300,
                                  width: 'auto'
                                }}
                                disabled={true}
                              />
                              {displayDataSelectField('Motif annulation', 'Motif', reasons ?? [])}
                              {displayTextField('cause', 'Cause')}
                              {displayDataTextField('créateur', 'Créateur')}
                              {displayDateTimePicker(
                                'intervention_creation_date',
                                'Date de création',
                                {
                                  read_only: true
                                }
                              )}
                              {displayDateTimePicker('updated_at', 'Date de mise à jour', {
                                isTimestamp: true,
                                read_only: true
                              })}
                              {displayDateTimePicker('expiration_date', "Date d'expiration")}
                              {displayDateTimePicker('closing_date', 'Date de réalisation')}
                              {displayDataTextField('Commentaire intervention', 'Commentaire', {
                                type: 'textarea'
                              })}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid container item xs={6}>
                        <Grid item xs={12} sx={{ mt: 2, ml: 2 }}>
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <BirdzTitle>PROGRAMMATION ET AFFECTATION</BirdzTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack spacing={2}>
                                {displayDateTimePicker(
                                  'Date de début prévue',
                                  'Date de début prévue',
                                  { inData: true }
                                )}
                                {displayDateTimePicker('Date de fin prévue', 'Date de fin prévue', {
                                  inData: true
                                })}
                                {displaySelectField('agent', 'Agent', agents)}
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 1, ml: 2 }}>
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <BirdzTitle>
                                PHOTOS {imageUrls?.length && `(${imageUrls?.length})`}
                              </BirdzTitle>
                            </AccordionSummary>

                            <AccordionDetails>
                              {!imageUrls || imageUrls.length === 0 ? (
                                <Alert severity="info">Aucun média disponible</Alert>
                              ) : (
                                <>
                                  <ImageList cols={5} rowHeight={200}>
                                    <>
                                      {currentPageData?.map((url: string, index: number) => {
                                        return (
                                          <ImageListItem
                                            sx={{ cursor: 'pointer' }}
                                            key={index}
                                            onClick={() => handleModalMediaChange(index)}
                                          >
                                            <Box>
                                              <img src={url} alt="" width="100%" />
                                            </Box>
                                          </ImageListItem>
                                        );
                                      })}
                                    </>
                                  </ImageList>

                                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button onClick={() => handleDownloadPhotos()}>
                                      Télécharger toutes les photos
                                    </Button>
                                  </Box>

                                  <Modal
                                    open={modalMedia !== undefined}
                                    onClose={() => setModalMedia(undefined)}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: '100vw',
                                        height: '100vh',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative'
                                      }}
                                      onClick={() => setModalMedia(undefined)}
                                    >
                                      {modalMedia && modalMedia !== null && (
                                        <>
                                          <Box
                                            sx={{
                                              position: 'relative',
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                left: '-50px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                backgroundColor: 'white',
                                                borderRadius: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                              }}
                                              onClick={handlePrevImage}
                                            >
                                              <IconButton>
                                                <ArrowBackIosIcon />
                                              </IconButton>
                                            </Box>

                                            <Box sx={{ position: 'relative' }}>
                                              <img
                                                src={modalMedia}
                                                alt=""
                                                style={{ maxWidth: '90%', maxHeight: '90%' }}
                                              />

                                              <Tooltip title="Télécharger la photo">
                                                <IconButton
                                                  sx={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    right: '10px',
                                                    height: '40px',
                                                    backgroundColor: 'white',
                                                    borderRadius: 8,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                  }}
                                                >
                                                  <a
                                                    style={{ color: 'black' }}
                                                    href={modalMedia}
                                                    download="new-image.jpg"
                                                  >
                                                    <DownloadIcon />
                                                  </a>
                                                </IconButton>
                                              </Tooltip>
                                            </Box>

                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                right: '30px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                backgroundColor: 'white',
                                                borderRadius: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                              }}
                                              onClick={handleNextImage}
                                            >
                                              <IconButton>
                                                <ArrowForwardIosIcon />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                  </Modal>

                                  {imageUrls.length > itemsPerPage && (
                                    <Stack alignContent={'flex-end'} alignItems={'end'}>
                                      <Pagination
                                        count={Math.ceil(imageUrls.length / itemsPerPage)}
                                        onChange={(event, page) => {
                                          setCurrentPage(page);
                                        }}
                                      />
                                    </Stack>
                                  )}
                                </>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <BirdzTitle>POINT DE SERVICE</BirdzTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Box>
                                    {displayDataSelectField(
                                      'CLIENT',
                                      'Client',
                                      companies ? companies : []
                                    )}
                                    {displaySelectField('contract_name', 'Contrat', contracts)}
                                    {displayTextField('pds', 'Numéro')}
                                    {displaySelectField(
                                      'module_family',
                                      "Famille d'équipement",
                                      moduleFamilies
                                    )}
                                    {displayDataTextField('Accès', 'Accès')}
                                    {displayDataTextField(
                                      'Commentaire de localisation',
                                      'Commentaire de localisation',
                                      {
                                        type: 'textarea'
                                      }
                                    )}
                                  </Box>

                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{ mt: 1, ml: 1, mr: 1, width: 'auto' }}
                                  >
                                    <AppPaper>
                                      <Grid item xs={12} sx={{ mt: 1, ml: 1 }}>
                                        Adresse
                                      </Grid>
                                      <Grid container item xs={12}>
                                        <Box sx={{ width: '10%' }}>
                                          {displayDataTextField('Numéro de rue', 'N°')}
                                        </Box>
                                        <Box sx={{ width: '90%' }}>
                                          {displayDataTextField('Nom de la rue', 'Rue')}
                                        </Box>
                                      </Grid>
                                      <Grid container item xs={12}>
                                        <Box sx={{ width: '30% ' }}>
                                          {displayTextField('zip_code', 'Code postal')}
                                        </Box>
                                        <Box sx={{ width: '70%' }}>
                                          {displayTextField('city', 'Commune')}
                                        </Box>
                                      </Grid>
                                    </AppPaper>
                                  </Grid>

                                  {displayTextField('latitude', 'Latitude')}
                                  {displayTextField('longitude', 'Longitude')}
                                </Grid>
                                <Grid item xs={6}>
                                  {moduleData && <DeviceMap moduleData={moduleData} />}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <BirdzTitle>CAPTEUR</BirdzTitle>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                Actuel
                              </Grid>
                              <Grid item xs={6}>
                                Ancien
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                {displayTextField('counter', 'Numéro')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayTextField('old_counter', 'Numéro', { read_only: true })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Marque compteur',
                                  'Marque',
                                  brands ? brands : [],
                                  { caseSensitive: true }
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Ancien compteur - Marque',
                                  'Marque',
                                  brands ? brands : [],
                                  { read_only: true }
                                )}
                              </Grid>
                              {!isWaterMeter && (
                                <Grid item xs={6}>
                                  {displayDataSelectField(
                                    'Modèle compteur',
                                    'Modèle',
                                    sensorModels ? sensorModels : [],
                                    { caseSensitive: true }
                                  )}
                                </Grid>
                              )}
                              {isWaterMeter && (
                                <Grid item xs={6}>
                                  {displayDataTextField('Modèle compteur', 'Modèle')}
                                </Grid>
                              )}

                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Ancien compteur - Modèle',
                                  'Modèle',
                                  sensorModels ? sensorModels : [],
                                  { read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Diamètre du compteur', 'Diamètre')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField('Ancien compteur - Diamètre', 'Diamètre', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDateTimePicker('counter_installation_date', 'Date de pose')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Ancien compteur - Date de pose',
                                  'Date de pose',
                                  { read_only: true, inData: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Index de pose', 'Index de pose')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien compteur - Index de pose',
                                  'Index de pose',
                                  { read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDateTimePicker('Date de dépose', 'Date de dépose', {
                                  inData: true
                                })}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Ancien compteur - date de dépose',
                                  'Date de dépose',
                                  { read_only: true, inData: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Index de dépose', 'Index de dépose')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien compteur - index de dépose',
                                  'Index de dépose',
                                  { read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Dernier index lu', 'Dernier index lu')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien compteur - Dernier index lu',
                                  'Dernier index lu',
                                  { read_only: true }
                                )}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <BirdzTitle>MODULE</BirdzTitle>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                Actuel
                              </Grid>
                              <Grid item xs={6}>
                                Ancien
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                {displayTextField('device_id', 'Adresse radio')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayTextField('old_device_id', 'Adresse radio', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayTextField('device_model', 'Type')}
                              </Grid>
                              <Grid item xs={6}></Grid>

                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Module - Mode radio',
                                  'Mode radio',
                                  radioModes ?? [],
                                  { id: true }
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Ancien module - Mode radio',
                                  'Mode radio',
                                  radioModes ?? [],
                                  { id: true, read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Module - Canal TX', 'Canal TX')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField('Ancien module - Canal TX', 'Canal TX', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Module - Canal LR 1', 'Canal LR 1')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField('Ancien module - Canal LR 1', 'Canal LR 1', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Module - Canal LR 2', 'Canal LR 2')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField('Ancien module - Canal LR 2', 'Canal LR 2', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataCheckBox('Module - DST', 'DST')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataCheckBox('Ancien module - DST', 'DST', {
                                  disabled: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataCheckBox(
                                  'Module - LoRaWAN auto-connect',
                                  'LoRaWAN auto-connect'
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataCheckBox(
                                  'Ancien module - LoRaWAN auto-connect',
                                  'LoRaWAN auto-connect',
                                  {
                                    disabled: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataCheckBox('Module - index horaire', 'Index horaire')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataCheckBox(
                                  'Ancien module - index horaire',
                                  'Index horaire',
                                  {
                                    disabled: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Module - LrwAppEUI', 'LrwAppEUI')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField('Ancien module - LrwAppEUI', 'LrwAppEUI', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Module - Clé métier', 'Clé métier')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField('Ancien module - Clé métier', 'Clé métier', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDateTimePicker('device_installation_date', 'Date de pose')}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Ancien module - date de pose',
                                  'Date de pose',
                                  {
                                    read_only: true,
                                    inData: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Module - index de pose', 'Index de pose')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien module - index de pose',
                                  'Index de pose',
                                  { read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Module - date de dépose',
                                  'Date de dépose',
                                  {
                                    inData: true
                                  }
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Ancien module - date de dépose',
                                  'Date de dépose',
                                  { read_only: true, inData: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Module - Index de dépose',
                                  'Index de dépose'
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien module - index de dépose',
                                  'Index de dépose',
                                  { read_only: true }
                                )}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <BirdzTitle>EQUIPEMENT PARENT</BirdzTitle>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                Actuel
                              </Grid>
                              <Grid item xs={6}>
                                Ancien
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                {displayTextField('equipment_parent', 'Adresse radio')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField('Ancien Repeteur Parent', 'Adresse radio', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Repeteur Parent - Mode radio',
                                  'Mode radio',
                                  radioModes ?? [],
                                  { id: true }
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Ancien Repeteur Parent - Mode radio',
                                  'Mode radio',
                                  radioModes ?? [],
                                  { id: true, read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Repeteur Parent - Canal TX', 'Canal TX')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - Canal TX',
                                  'Canal TX',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Repeteur Parent - Canal RX', 'Canal RX')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - Canal RX',
                                  'Canal RX',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Repeteur Parent - LrwAppEUI', 'LrwAppEUI')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - LrwAppEUI',
                                  'LrwAppEUI',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Repeteur Parent - date de pose',
                                  'Date de pose',
                                  { inData: true }
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Ancien Repeteur Parent - date de pose',
                                  'Date de pose',
                                  { read_only: true, inData: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Repeteur Parent - date de dépose',
                                  'Date de dépose',
                                  { inData: true }
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDateTimePicker(
                                  'Ancien Repeteur Parent - date de dépose',
                                  'Date de dépose',
                                  { read_only: true, inData: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Repeteur Parent - Type de Support',
                                  'Type de support',
                                  supportType ?? []
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Ancien Repeteur Parent - Type de Support',
                                  'Type de support',
                                  supportType ?? [],
                                  { read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataSelectField('Repeteur Parent - RAL', 'RAL', ral ?? [])}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataSelectField(
                                  'Ancien Repeteur Parent - RAL',
                                  'RAL',
                                  ral ?? [],
                                  { read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Repeteur Parent - Référence de Support',
                                  'Référence de support'
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - Référence de Support',
                                  'Référence de support',
                                  { read_only: true }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Repeteur Parent - Numéro de rue', 'N°')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - Numéro de rue',
                                  'N°',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Repeteur Parent - attribut du numéro de rue',
                                  'Attribut du n° de rue'
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - attribut du numéro de rue',
                                  'Attribut du n° de rue',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Repeteur Parent - Nom de la rue',
                                  'Nom de la rue'
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - Nom de la rue',
                                  'Nom de la rue',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Repeteur Parent - Code postal',
                                  'Code postal'
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - Code postal',
                                  'Code postal',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Repeteur Parent - Commune', 'Commune')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - Commune',
                                  'Commune',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField('Repeteur Parent - Accès', 'Accès')}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField('Ancien Repeteur Parent - Accès', 'Accès', {
                                  read_only: true
                                })}
                              </Grid>

                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Repeteur Parent - localisation',
                                  'Localisation'
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {displayDataTextField(
                                  'Ancien Repeteur Parent - localisation',
                                  'Localisation',
                                  {
                                    read_only: true
                                  }
                                )}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                    {isVisible && (
                      <button
                        type="button"
                        style={{
                          position: 'fixed',
                          bottom: '20px',
                          right: '30px',
                          zIndex: 99,
                          fontSize: '18px',
                          border: 'none',
                          outline: 'none',
                          backgroundColor: '#555',
                          color: 'white',
                          cursor: 'pointer',
                          padding: '15px',
                          borderRadius: '4px'
                        }}
                        onClick={(event) => scrollToTop(event)}
                      >
                        &#8679;
                      </button>
                    )}
                  </Grid>
                )}
              </Form>
            );
          }}
        </Formik>
      }

      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </>
  );
}
