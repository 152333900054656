import {
  Alert,
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { StyledTableHeaderRow } from '../styles/Table.styled';
import {
  AppPaper,
  BirdzModal,
  BirdzModalTitle,
  BirdzModalContent,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { fr } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  PiFilePpt,
  PiFileJpg,
  PiFilePdf,
  PiFilePng,
  PiFile,
  PiFileXls,
  PiFileDoc
} from 'react-icons/pi';

const SamplePage = () => {
  return (
    <AppPaper>
      <BirdzTitle>AppPaper</BirdzTitle>
      <Button
        variant="outlined"
        onClick={() => {
          throw Error('Test erreur sentry support-front');
        }}
      >
        Send sentry error
      </Button>
      <p>
        Le contenu de la page se trouve dans un{' '}
        <code>
          <>&lt;AppPaper /&gt;</>
        </code>
      </p>
      <TextField name={'test'} size={'small'} fullWidth />
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Culpa, optio. Illum consequatur,
        repudiandae ullam esse ratione consequuntur quasi perferendis labore, est nobis eos nam
        maiores doloribus, voluptas veniam necessitatibus. Facere?
      </p>
      Icon fontawesome
      <Box>
        <PiFileXls />
        <PiFileDoc />
        <PiFile />
        <PiFilePdf />
        <PiFilePpt />
        <PiFilePng />
        <PiFileJpg />
      </Box>
      <BirdzTitle>DatePicker FR</BirdzTitle>
      <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
        <DatePicker
          onChange={(value) => {
            //eslint-disable-next-line
            console.log('bla', value);
          }}
          // value={'12/03/2023'}
          // renderInput={(params) => <TextField {...params} size="small" />}
        />
      </LocalizationProvider>
      <BirdzTitle>Traduction</BirdzTitle>
      <p>
        Test traduction soit avec le hook "t" <code>&#123;t('test')&#125;</code> mais aussi avec le
        component <code>&lt;Trans&gt;test&lt;/Trans&gt;</code>
      </p>
      <BirdzTitle>Messages</BirdzTitle>
      <Alert severity="error" sx={{ mt: 2 }}>
        Message d'erreur
      </Alert>
      <Alert severity="warning" sx={{ mt: 2 }}>
        Message d'alert
      </Alert>
      <Alert severity="info" sx={{ mt: 2 }}>
        Message d'info
      </Alert>
      <Alert severity="success" sx={{ mt: 2 }}>
        Message de succès
      </Alert>
      <BirdzTitle>Boutons</BirdzTitle>
      <Stack spacing={2} direction="row">
        <Button variant="contained">Bouton principal</Button>
        <Button variant="outlined">Bouton secondaire</Button>
        <Button>Bouton sans contour</Button>
      </Stack>
      <BirdzTitle>Tableaux</BirdzTitle>
      <p>
        La plupart des tableaux présentant beaucoup de données, on les utilisera avec l'attribut
        size="small"
      </p>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table size="small">
          <TableHead>
            <StyledTableHeaderRow>
              <TableCell>Blabla</TableCell>
              <TableCell>Couverture réseau</TableCell>
              <TableCell colSpan={2}>Qualité de service</TableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Lorem</TableCell>
              <TableCell>Ipsum</TableCell>
              <TableCell>Dolor</TableCell>
              <TableCell>Sit amet</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lorem</TableCell>
              <TableCell>Ipsum</TableCell>
              <TableCell>Dolor</TableCell>
              <TableCell>Sit amet</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lorem</TableCell>
              <TableCell>Ipsum</TableCell>
              <TableCell>Dolor</TableCell>
              <TableCell>Sit amet</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <BirdzModal open={false}>
        <BirdzModalTitle>Titre de la modale</BirdzModalTitle>
        <BirdzModalContent>Contenu de la modale</BirdzModalContent>
      </BirdzModal>
    </AppPaper>
  );
};
export default SamplePage;
