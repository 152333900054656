import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import './DashboardLayout.scss';
import moment from 'moment';
import { Box, Tooltip } from '@mui/material';
import routes from '../../routes';
import { Breadcrumbs } from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
import { useGlobalContext } from '../../contexts/globalContext';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const DashboardLayout = ({ component, ...props }: any) => {
  const { ...rest } = props;
  const { theme, sidebarIsExpanded } = useGlobalContext();

  return (
    <div className={`layout-wrapper ${theme} ${authService.BOIorBOE('boi', 'boe')}`}>
      <Sidebar {...rest} />

      <Box
        className={`main-panel ${!sidebarIsExpanded ? 'expand' : ''}`}
        sx={{ px: 2, boxSizing: 'border-box' }}
      >
        <Box
          className="d-flex align-items-center justify-content-between"
          sx={{ borderBottom: '1px solid', borderColor: 'divider', height: '50px', mb: 2 }}
        >
          <Breadcrumbs routes={routes} />
          <Tooltip
            placement="left"
            title={
              authService.user?.previous_login ? (
                <span className="last-login">
                  Dernière connexion le{' '}
                  {moment(authService.user.previous_login).format('DD/MM/YYYY à HH:mm:ss')}
                </span>
              ) : (
                ''
              )
            }
          >
            <Box className="navbar">
              {authService.user ? authService.user.name : ''}
              <Box
                className="d-flex align-items-center justify-content-center avatar-container"
                sx={{
                  ml: 1
                }}
              >
                {authService.user?.avatar ? (
                  <div
                    style={{
                      backgroundImage: 'url("' + authService.user.avatar + '"',
                      backgroundPosition: 'center',
                      height: '30px',
                      width: '30px',
                      backgroundSize: 'cover'
                    }}
                  />
                ) : (
                  <AccountCircleRoundedIcon fontSize="large" />
                )}
              </Box>
            </Box>
          </Tooltip>
        </Box>

        <div className="page-content">
          <div className="inner">{component}</div>
        </div>
      </Box>
    </div>
  );
};

export default DashboardLayout;
