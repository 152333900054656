import axios from 'axios';
import { isObject } from 'lodash';
import moment from 'moment';

export const downloadFile = (url: string, fileName: string) => {
  axios({
    //Found on stackoverflow -> https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
    url: url,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeAccents = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const getFilterString = (filterValues: { [fieldName: string]: any }) => {
  let filterString = '';
  Object.keys(filterValues).forEach((filterName) => {
    if (typeof filterValues[filterName] === 'undefined' || filterValues[filterName] === null) {
      return;
    }
    if (filterName === 'campaignId') {
      filterString += `&campaign=${filterValues[filterName]}`;
    }
    if (filterName.includes('created_at')) {
      filterString += `&${filterName}=${moment(filterValues[filterName]).format('YYYY-MM-DD')}`;
    } else if (filterValues[filterName] instanceof Array) {
      const filterNameSuffix =
        filterValues[filterName].length > 1 &&
        filterName.substring(filterName.length - 4) !== '__in'
          ? '__in'
          : '';
      filterString += `&${filterName}${filterNameSuffix}=${filterValues[filterName].map((obj: { value: string | number }) => obj.value).join(',')}`;
    } else if (isObject(filterValues[filterName]) && 'value' in filterValues[filterName]) {
      filterString += `&${filterName}=${filterValues[filterName].value}`;
    } else {
      filterString += `&${filterName}=${filterValues[filterName]}`;
    }
  });
  return filterString;
};

export const removeFalsyValues = (obj: Record<string, any>): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => {
      // Keep entries where the value is not falsy, empty string, or empty array
      return Boolean(value) && !(Array.isArray(value) && value.length === 0) && value !== '';
    })
  );
};

export function removeDuplicates(arr: any[]) {
  return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
}
