import React, { useEffect, useState } from 'react';
import { Box, Paper, TextField } from '@mui/material';
import { SearchField } from '@applications-terrains/birdz-react-library';
import useDebouncedValue from '../../../../../hooks/useDebouncedValue';

const CustomTextField = ({
  onSearch,
  field,
  placeholder,
  noOptions,
  ...props
}: {
  onSearch: (search: string) => void;
  placeholder: string;
  noOptions: boolean;
  field: SearchField;
}) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebouncedValue(inputValue, 300); // Déclencher après 300ms d'inactivité

  useEffect(() => {
    onSearch(debouncedValue);
  }, [debouncedValue, onSearch]);

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        label={field.label}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        placeholder={placeholder}
        // value={inputValue}
        {...props}
      />
      {noOptions && (
        <Paper
          sx={{ position: 'absolute', height: '52px' }}
          className="w-100 d-flex align-items-center"
        >
          <Box
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              pl: 2,
              fontSize: '1rem',
              height: '20px'
            }}
          >
            aucun résultat
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default CustomTextField;
