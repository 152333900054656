import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { PatrimonyItem } from './Types';
import { StyledTableBodyRow, TableBoldCell } from '../../../styles/Table.styled';

type CrCasePatrimonyProps = {
  results?: PatrimonyItem;
  title?: string;
};

const CrCasePatrimony = ({ results, title }: CrCasePatrimonyProps) => {
  const patrimonyLabels = {
    region: 'Region',
    site: 'Site',
    pdc_pdr: 'PDC/PDR',
    device_id: 'Adresse Radio',
    equipment_type: "Type d'équipement ",
    contract_code: 'Code contrat ',
    contract_label: 'Libellé contrat',
    street_number: 'Numéro de voie',
    street_name: 'Nom de voie',
    zip_code: 'Code postal',
    city: 'Commune',
    install_date: "Date d'installation",
    m2o_service: 'Service M2O ',
    latitude: 'Latitude',
    longitude: 'Longitude',
    location_comments: 'Commentaire Localisation ',
    nb_module_exclusive: 'Nb modules exclusifs',
    nb_module_total: 'Nb modules total',
    power_supply_type: "Type d'alimentation",
    cr_type: 'CR type',
    location_type: 'Type de site'
  };

  const getPowerSupplyTypesTranslations = (
    powerSupplyType: 'POWER_OUTLET' | 'BATTERY' | 'SOLAR_PANEL' | string | null | undefined
  ): string => {
    const powerSupplyTypesTranslations: Record<string, string> = {
      POWER_OUTLET: 'Secteur',
      BATTERY: 'Batterie',
      SOLAR_PANEL: 'Panneaux solaires',
      default: 'Inconnu'
    };

    return powerSupplyType
      ? powerSupplyTypesTranslations[powerSupplyType] || powerSupplyType
      : powerSupplyTypesTranslations['default'];
  };

  return (
    <Paper variant="outlined" square sx={{ mt: 2 }}>
      <Accordion sx={{ boxShadow: 'none' }} disableGutters={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {title ? <h3>{title}</h3> : <h3>Patrimoine</h3>}
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                <StyledTableBodyRow>
                  <TableBoldCell width="300">{patrimonyLabels['region']}</TableBoldCell>
                  <TableCell colSpan={3}>{results?.region || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['site']}</TableBoldCell>
                  <TableCell colSpan={3}>{results?.site || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['pdc_pdr']}</TableBoldCell>
                  <TableCell colSpan={3}>{results?.pdc_pdr || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['device_id']}</TableBoldCell>
                  <TableCell colSpan={3}>{results?.device_id || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['equipment_type']}</TableBoldCell>
                  <TableCell colSpan={3}>{results?.equipment_type || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['contract_code']}</TableBoldCell>
                  <TableCell>{results?.contract_code || '-'}</TableCell>
                  <TableBoldCell width="300">{patrimonyLabels['contract_label']}</TableBoldCell>
                  <TableCell>{results?.contract_label || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['street_number']}</TableBoldCell>
                  <TableCell>{results?.street_number || '-'}</TableCell>
                  <TableBoldCell>{patrimonyLabels['street_name']}</TableBoldCell>
                  <TableCell>{results?.street_name || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['zip_code']}</TableBoldCell>
                  <TableCell>{results?.zip_code || '-'}</TableCell>
                  <TableBoldCell>{patrimonyLabels['city']}</TableBoldCell>
                  <TableCell>{results?.city || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['install_date']}</TableBoldCell>
                  <TableCell colSpan={3}>
                    {results?.install_date
                      ? moment(results?.install_date, 'YYYY-MM-DD,h:mm:ss').format(
                          'DD/MM/YYYY à HH:mm:ss'
                        )
                      : '-'}
                  </TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['m2o_service']}</TableBoldCell>
                  <TableCell colSpan={3}>{results?.m2o_service || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['latitude']}</TableBoldCell>
                  <TableCell>{results?.latitude || '-'}</TableCell>
                  <TableBoldCell>{patrimonyLabels['longitude']}</TableBoldCell>
                  <TableCell>{results?.longitude || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['nb_module_exclusive']}</TableBoldCell>
                  <TableCell colSpan={3}>{results?.nb_module_exclusive || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['nb_module_total']}</TableBoldCell>
                  <TableCell>{results?.nb_module_total || '-'}</TableCell>
                  <TableBoldCell rowSpan={3}>{patrimonyLabels['location_comments']}</TableBoldCell>
                  <TableCell rowSpan={3}>{results?.location_comments || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['power_supply_type']}</TableBoldCell>
                  <TableCell>
                    {getPowerSupplyTypesTranslations(results?.power_supply_type)}
                  </TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['cr_type']}</TableBoldCell>
                  <TableCell>{results?.cr_type || '-'}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableBoldCell>{patrimonyLabels['location_type']}</TableBoldCell>
                  <TableCell>{results?.location_type || '-'}</TableCell>
                </StyledTableBodyRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default CrCasePatrimony;
