import React, { useState } from 'react';
import { Autocomplete, Box, CircularProgress } from '@mui/material';
import CustomTextField from './CustomTextField';
import { FieldType } from '../../types';
import { useFetchDataRefsContext } from '../../../../../contexts/FetchDataRefsContext';
import useFetchData from '../../../../../hooks/useFetchData';
import { uniqBy } from 'lodash';

const SearchableSelect = ({
  field,
  values,
  setFieldValue,
  endpoint
}: FieldType & { endpoint: string }) => {
  const [search, setSearch] = useState('');
  const [focused, setFocused] = useState(false);
  const { dataRefs } = useFetchDataRefsContext();
  const dataRefFieldName = field.name;

  if (!field.searchTargetKey) throw new Error('searchTargetKey must be provided');

  const enableFetch =
    (!field.searchable && !(dataRefFieldName in dataRefs)) ||
    (field.searchable === true && search.length > 2);

  const fetchEndpoint = enableFetch
    ? `${endpoint}${search.length > 2 ? `?${field.searchTargetKey}=` + search : ''}`
    : endpoint;

  const getFormattedFetchEndpoint = (endpoint: string) =>
    endpoint[endpoint.length - 1] === '?' || endpoint[endpoint.length - 1] === '&'
      ? endpoint.slice(0, -1)
      : endpoint;

  const { isLoading } = useFetchData({
    key: dataRefFieldName,
    endpoint: getFormattedFetchEndpoint(fetchEndpoint),
    resultsField: field.options?.resultsField,
    enableFetch
  });

  const options = uniqBy(dataRefs[dataRefFieldName]?.results, field.searchTargetKey) || [];
  const value = values[field.name];

  return (
    <Autocomplete
      size="small"
      className="w-100"
      multiple={field.multiple}
      value={value}
      onChange={(_, newValue) => setFieldValue(field.name, newValue)}
      options={options
        .map((el: { [key: string]: any }) => ({
          value: el[field.objectFormat?.value as keyof typeof el],
          label: el[field.objectFormat?.label as keyof typeof el]
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
      getOptionLabel={(option) => option.label || ''}
      groupBy={(option) => option.group || ''}
      isOptionEqualToValue={(option: any, value: any) => {
        return option.value === value.value;
      }}
      onBlur={() => {
        setFocused(false);
      }}
      onFocus={() => {
        setFocused(true);
      }}
      renderInput={(params) => {
        return (
          <CustomTextField
            field={field}
            onSearch={(search) => (field.searchable === true ? setSearch(search) : null)}
            placeholder={field.searchable === true ? 'minimum 3 caractères' : ''}
            noOptions={focused && search.length > 0 && options.length === 0 && !isLoading}
            {...params}
          />
        );
      }}
      loading={isLoading && search.length > 2}
      loadingText={
        <Box className="d-flex align-items-center">
          recherche en cours... <CircularProgress size={16} sx={{ ml: 1 }} />
        </Box>
      }
      disableCloseOnSelect={field.multiple === true}
      freeSolo
      forcePopupIcon
    />
  );
};

export default SearchableSelect;
