import React, { useEffect, useState } from 'react';
import {
  BirdzTitle,
  ListPage,
  AppPaper,
  ListField,
  useNotif,
  BirdzNotif,
  SearchField
} from '@applications-terrains/birdz-react-library';
import { Button, Grid, IconButton } from '@mui/material';
import moment from 'moment';
import {
  formatToSelectOptions,
  useGetCaseStatuses,
  useGetContracts,
  useGetYears
} from '../../../hooks/datarefs';
import axios from 'axios';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/RemoveRedEye';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { MRCASETYPE } from './Types';
import { PiFileCsv } from 'react-icons/pi';

export default function MrCases() {
  const endpoint = '/api/boi/cases/mr-cases/';
  const { notif, notifOptions } = useNotif();
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);

  const { data: contracts } = useGetContracts();
  const { data: years } = useGetYears();
  const { data: caseStatus } = useGetCaseStatuses();
  const actionTypes = [
    { id: 1, label: 'Maintenance' },
    { id: 2, label: 'Déploiement' }
  ];

  const [subcontractors, setSubcontractors] = useState<any>();

  useEffect(() => {
    const getSubcontractors = async () => {
      await axios
        .get('/api/boi/subcontractors/subcontractors/?action_type=MR')
        .then((response: any) => {
          const data = response.data.results;
          setSubcontractors(data);
        });
    };

    getSubcontractors();
  }, []);

  useEffect(() => {
    const searchFields = [
      {
        name: 'created_at_after',
        id: 'search_by_date_after',
        label: 'Entre le',
        type: 'datepicker',
        options: {
          format: 'YYYY-MM-DD'
        }
      },
      {
        name: 'created_at_before',
        id: 'search_by_date_before',
        label: 'Et le',
        type: 'datepicker',
        options: {
          format: 'YYYY-MM-DD'
        }
      },
      {
        name: 'number',
        label: 'Numéro de dossier',
        options: {
          identifier: 'number',
          source: endpoint,
          label: 'number',
          searchIsMulti: false
        }
      },
      {
        name: 'device_id',
        label: 'Adresse radio',
        type: 'text'
      },
      {
        name: 'contract_code',
        id: 'search_by_contract',
        label: 'Code contrat',
        type: 'select',
        options: {
          values: formatToSelectOptions(contracts, {
            label: 'contract_ve',
            value: 'contract_ve'
          })
        }
      },
      {
        name: 'region_veolia',
        label: 'Commune / CP',
        type: 'text'
      },
      {
        name: 'subcontractor',
        label: 'Sous-traitant',
        type: 'select',
        options: {
          values: formatToSelectOptions(subcontractors, {
            label: 'name'
          })
        }
      },
      {
        name: 'has_interventions',
        label: 'Avec interventions',
        type: 'select',
        options: {
          values: [
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' }
          ]
        }
      },
      {
        name: 'status',
        label: 'Statut dossier',
        type: 'select',
        options: {
          values: formatToSelectOptions(caseStatus)
        }
      },
      {
        name: 'year_id',
        label: 'Année',
        type: 'select',
        options: {
          values: formatToSelectOptions(years)
        }
      },
      {
        name: 'action_type',
        label: 'Type',
        type: 'select',
        options: {
          values: formatToSelectOptions(actionTypes)
        }
      }
    ];
    setSearchFields(searchFields);
  }, [caseStatus, years, subcontractors, contracts]);

  const listFields: ListField[] = [
    {
      name: 'number',
      label: 'Numéro de dossier'
    },
    {
      name: 'action_type',
      label: 'Type',
      transform: (typeId: number) => {
        if (typeId === MRCASETYPE.DEPLOYMENT) return 'Déploiement';
        if (typeId === MRCASETYPE.MAINTENANCE) return 'Maintenance';
        return '-';
      }
    },
    {
      name: 'created_at',
      label: 'Date de création',
      transform: (value: any) => {
        return moment.unix(value).format('DD/MM/YYYY');
      }
    },
    {
      name: 'actions',
      label: 'Actions',
      transform: (value: string, row: any) => {
        return (
          <Link to={`/boi/mr/cases/edit/${row.id}`}>
            <IconButton aria-label="Intervention détail">
              <VisibilityIcon id="visibilityIcon" />
            </IconButton>
          </Link>
        );
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste des dossiers</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to="/boi/mr/diagnostics">
            <Button variant="contained" sx={{ mt: 3 }}>
              <AddCircleIcon sx={{ mr: 1 }} /> Nouveau dossier
            </Button>
          </Link>
        </Grid>
      </Grid>

      <ListPage
        endpoint={endpoint}
        fields={listFields}
        searchFields={searchFields}
        defaultOrder={['-updated_at']}
        exportButtons={[
          {
            name: 'Exporter en csv',
            type: 'csv',
            icon: <PiFileCsv />,
            requestEndpoint: '/api/boi/cases/mrcase-csv-export/',
            keepQueryParams: false,
            onSuccess: () => {
              notif({
                type: 'success',
                content: 'Export réussi'
              });
            }
          }
        ]}
      />

      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}
