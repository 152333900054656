import React, { FC } from 'react';
import { FetchContextProvider } from './fetchContext';
import { ResetFormContextProvider } from './resetFormContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import fr from 'date-fns/locale/fr';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FetchDataRefsContextProvider } from './FetchDataRefsContext';

export type Children = {
  children?: React.ReactNode;
};

const AppContextsProvider: FC<Children> = ({ children }) => {
  const queryClient = new QueryClient();

  return (
    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <FetchContextProvider>
          <FetchDataRefsContextProvider>
            <ResetFormContextProvider>{children}</ResetFormContextProvider>
          </FetchDataRefsContextProvider>
        </FetchContextProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};

export default AppContextsProvider;
