import React, { ReactNode, createContext, useContext, useState } from 'react';

type FetchDataRefsContextType = {
  dataRefs: any;
  setDataRefs: any;
};

const FetchDataRefsContext = createContext<FetchDataRefsContextType | undefined>(undefined);

export const useFetchDataRefsContext = () => {
  const context = useContext(FetchDataRefsContext);

  if (context === undefined) {
    throw new Error('useFetchDataRefsContext must be used with a FetchDataRefsContext');
  }

  return context;
};

export const FetchDataRefsContextProvider = ({ children }: { children: ReactNode }) => {
  const [dataRefs, setDataRefs] = useState<{
    [key: string]: {
      count: number;
      endpoint: string;
      next?: string | null;
      previous?: string | null;
      results: object[];
    };
  }>({});

  const value = {
    dataRefs,
    setDataRefs
  };

  return <FetchDataRefsContext.Provider value={value}>{children}</FetchDataRefsContext.Provider>;
};
