import React, { useEffect } from 'react';
import { InterventionsFiltersContextProvider } from './context/interventionsFiltersContext';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function ExtractorInterventionsWrapper() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === '/bconnect/interventions') navigate('/bconnect/interventions/dashboard');
  }, [navigate, pathname]);
  return (
    <InterventionsFiltersContextProvider>
      <Outlet />
    </InterventionsFiltersContextProvider>
  );
}
